import {
  ADD_TO_CART,
  REMOVE_FROM_CART,
  CART_SAVE_FACTURACION,
  CART_SAVE_PAYMENT,
} from "../Types";

export const addToCart = (product) => (dispatch, getState) => {
  console.log(getState())
  const cartItems = getState().cart.cartItems.slice();

  let alreadyExists = false;

  cartItems.forEach((x) => {
    if (x.id_producto === product.id_producto) {
      alreadyExists = true;
      x.count++;
    }
  });
  if (!alreadyExists) {
    cartItems.push({ ...product, count: 1 });
  }
  dispatch({
    type: ADD_TO_CART,
    payload: { cartItems },
  });
  localStorage.setItem("cartItems", JSON.stringify(cartItems));
};

export const removeFromCart = (product) => (dispatch, getState) => {
  const cartItems = getState()
    .cart.cartItems.slice()
    .filter((x) => x.id_producto !== product.id_producto);
  dispatch({ type: REMOVE_FROM_CART, payload: { cartItems } });
  localStorage.setItem("cartItems", JSON.stringify(cartItems));
};
export const saveFacturacion = (data) => (dispatch) => {
  dispatch({ type: CART_SAVE_FACTURACION, payload: data });
  localStorage.setItem("HomeCompra", JSON.stringify(data));
};
export const savePayment = (data) => (dispatch) => {
  dispatch({ type: CART_SAVE_PAYMENT, payload: data });
};
