import React, { Component } from "react";
import formatCurrency from "../components/Util";
import Fade from "react-reveal/Fade";
import Zoom from "react-reveal/Zoom";
import { connect } from "react-redux";


import { removeFromCart } from "../actions/CartActions";


class Cart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      address: "",
      showCheckout: false,
    };
  }
  handleInput = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  createOrder = (e) => {
    e.preventDefault();
    const order = {
      name: this.state.name,
      email: this.state.email,
      address: this.state.address,
      cartItems: this.props.cartItems,
      total: this.props.cartItems.reduce((a, c) => a + c.price * c.count, 0),
    };
    this.props.createOrder(order);
  };

  render() {
    const { cartItems } = this.props;
    

   
    return (
      <div>
        {cartItems.length === 0 ? (
          <div className="cart-cart-header"><p>El carrito esta vacío</p></div>
        ) : (
          <div className="cart-cart-header">
           <p> Tienes {cartItems.length} productos en el carrito{" "}</p>
            <br/><br/><br/>
          </div>
        )}
        <div>
          <div className="cart">
            <Fade left cascade>
              <ul className="cart-items">
                {cartItems.map((item) => (
                  <li key={item.id_producto}>
                    <div className="imagen-carrito">
                    <img src={item.image} alt={item.name}></img>
                    </div>
                    <div className="parrafo-carrito">
                    <p className="color-verde">Nivel {item.name_category}</p>
                    <p className="color-orange">{item.name}</p>
                    </div>

                    <div className="cart-price">
                    <p>{formatCurrency(item.price)} </p>
                    </div>
                      <div className="cart-price-tachito">
                     
                        <button
                          className="cart-button"
                          onClick={() => this.props.removeFromCart(item)}
                        >
                        <img src="./imagenes/FT3-18.png" className="redes-sociales-basura" alt="eliminar"/>
                        </button>
                      
                    </div>
                  </li>
                ))}
              </ul>
            </Fade>
          </div>
        </div>
      </div>
    );
  }
}

export default connect ((state) =>({
cartItems : state.cart.cartItems,
}),
{ removeFromCart}
)(Cart);