import {
  FILTER_PRODUCTS_BY_CATEGORY,
  ORDER_PRODUCTS_BY_PRICE,
  FETCH_PRODUCTS,
  FETCH_PRODUCTS2,
} from "../Types";
import { URL_SERVER } from "../constants/userConstants";
import {
  PRODUCT_LIST_REQUEST,
  PRODUCT_LIST_SUCCESS,
  PRODUCT_LIST_FAIL,
  PRODUCT_REVIEW_SAVE_REQUEST,
  PRODUCT_REVIEW_SAVE_FAIL,
  PRODUCT_REVIEW_SAVE_SUCCESS,
  PRODUCT_DETAILS_REQUEST,
  PRODUCT_DETAILS_SUCCESS,
  PRODUCT_DETAILS_FAIL,
  FILTER_PRODUCTS_BY_CATEGORY2,
} from "../constants/productConstants";
import axios from "axios";
import Axios from "axios";
import swal from 'sweetalert';

export const fetchProducts = () => async (dispatch) => {
  dispatch({
    type: PRODUCT_LIST_REQUEST,
  });

  try {
    const { data } = await Axios.post(URL_SERVER + "/listProducts");
    // console.log(data.data.products);
    // console.log(data.data.promotions);
    dispatch({ type: FETCH_PRODUCTS, payload: data.data });
  } catch (error) {
    dispatch({ type: PRODUCT_LIST_FAIL, payload: error.message });
    swal({
      title: "¡Ups !",
      text: error.message,
      icon: "error",
      dangerMode: true,
    })
    .then(willDelete => {
      if (willDelete) {
       window.location.replace('/');
       
      }
    });
  }
};

export const filterProducts = (products, id_categoria) => (dispatch) => {
  const filProduct = products.products.filter(
    (x) => x.id_categoria.indexOf(id_categoria) >= 0
  );
  const category = products.categories.filter(
    (x) => x.id_categoria.indexOf(id_categoria) >= 0
  );
  const promotions = products.promotions.filter(
    (x) => x.id_categoria.indexOf(id_categoria) >= 0
  );
  const productos = {
    products: filProduct,
    categories: category,
    promotions: promotions,
  };
  console.log(productos, products);
  dispatch({
    type: FILTER_PRODUCTS_BY_CATEGORY,
    payload: {
      id_categoria: id_categoria,
      items: productos,
    },
  });
};

export const filterProducts2 = (products, name) => (dispatch) => {
  const filProduct = products.products.filter(
    (x) => x.name.indexOf(name) >= 0
  );
  const category = products.categories.filter(
    (x) => x.name.indexOf(name) >= 0
  );
  const promotions = products.promotions.filter(
    (x) => x.name.indexOf(name) >= 0
  );
  const productos = {
    products: filProduct,
    categories: category,
    promotions: promotions,
  };
  console.log(productos, products);
  dispatch({
    type: FILTER_PRODUCTS_BY_CATEGORY,
    payload: {
      name: name,
      items: productos,
    },
  });
};





export const sortProducts = (filteredProducts, sort) => (dispatch) => {
  const sortedProducts = filteredProducts.slice();
  if (sort === "latest") {
    sortedProducts.sort((a, b) => (a._id > b._id ? 1 : -1));
  } else {
    sortedProducts.sort((a, b) =>
      sort === "lowest"
        ? a.price > b.price
          ? 1
          : -1
        : a.price > b.price
        ? -1
        : 1
    );
  }
  console.log(sortedProducts);
  dispatch({
    type: ORDER_PRODUCTS_BY_PRICE,
    payload: {
      sort: sort,
      items: sortedProducts,
    },
  });
};

// export const listProducts = (
//   category = '',
//   searchKeyword = '',
//   sortOrder = ''
// ) => async (dispatch) => {
//   try {
//     dispatch({ type: PRODUCT_LIST_REQUEST });
//     const { data } = await axios.post(URL_SERVER+
//       '/products?category=' +
//         category +
//         '&searchKeyword=' +
//         searchKeyword
//     );
//     dispatch({ type: PRODUCT_LIST_SUCCESS, payload: data });
//   } catch (error) {
//     dispatch({ type: PRODUCT_LIST_FAIL, payload: error.message });
//   }
// };

export const detailsProduct = (productId) => async (dispatch) => {
  //console.log("##debug")
  try {
    dispatch({ type: PRODUCT_DETAILS_REQUEST, payload: productId });
    const data = await Axios.put(URL_SERVER + `/products/${productId}`);
    console.log("details", data);
    dispatch({ type: PRODUCT_DETAILS_SUCCESS, payload: data.data });
  } catch (error) {
    dispatch({ type: PRODUCT_DETAILS_FAIL, payload: error.message });
    swal({
      title: "¡Ups !",
      text: error.message,
      icon: "error",
      dangerMode: true,
    })
    .then(willDelete => {
      if (willDelete) {
       window.location.replace('/');
       
      }
    });
  }
  //console.log("##fin");
};

export const saveProductReview = (
  comment,
  rating,
  id_producto,
  id_usuario
) => async (dispatch) => {
 // console.log(comment, rating, id_producto, id_usuario);

  try {
    dispatch({
      type: PRODUCT_REVIEW_SAVE_REQUEST,
      payload: {
        comment: comment,
        rating: rating,
        id_producto: id_producto,
        id_usuario: id_usuario,
      },
    });
    // const get = `?comment=${comment}&rating=${rating}&id_producto=${id_producto}&id_usuario=${id_usuario}`
    const { data } = await axios.post(URL_SERVER + `/products/reviews`, {
      comment: comment,
      rating: rating,
      id_producto: id_producto,
      id_usuario: id_usuario,
    });

    dispatch({ type: PRODUCT_REVIEW_SAVE_SUCCESS, payload: data.data });
  } catch (error) {
    dispatch({ type: PRODUCT_REVIEW_SAVE_FAIL, payload: error.message });
    swal({
      title: "¡Ups !",
      text: error.message,
      icon: "error",
      dangerMode: true,
    })
    .then(willDelete => {
      if (willDelete) {
       window.location.replace('/');
       
      }
    });
  }
};

export const detailsProduct2 = async (productId) => {
  //console.log("##debug")
  try {
    const data = await Axios.put(URL_SERVER + `/products/${productId}`);
    // console.log("details",data);
    return data;
  } catch (error) {
    swal({
      title: "¡Ups !",
      text: error.message,
      icon: "error",
      dangerMode: true,
    })
    .then(willDelete => {
      if (willDelete) {
       window.location.replace('/');
       
      }
    });
  }
  //console.log("##fin");
};


