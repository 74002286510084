import {
  ARTICULO_DETAILS_FAIL,
  ARTICULO_DETAILS_REQUEST,
  ARTICULO_DETAILS_SUCCESS,
  ARTICULO_REVIEW_SAVE_FAIL,
  ARTICULO_REVIEW_SAVE_REQUEST,
  ARTICULO_REVIEW_SAVE_RESET,
  ARTICULO_REVIEW_SAVE_SUCCESS,
    FETCH_ARTICULOS
  } from "../Types";
  
  export const articulosReducer = (state = {}, action) => {
    switch (action.type) {

      case FETCH_ARTICULOS:
        return { items: action.payload, filteredItems: action.payload };
      default:
        return state;
    }
  };

  export const articuloReviewSaveReducer = (state = {}, action) => {
    switch (action.type) {
      case ARTICULO_REVIEW_SAVE_REQUEST:
        return { loading: true };
      case ARTICULO_REVIEW_SAVE_SUCCESS:
        return { loading: false, review: action.payload, success: true };
      case ARTICULO_REVIEW_SAVE_FAIL:
        return { loading: false, errror: action.payload };
      case ARTICULO_REVIEW_SAVE_RESET:
        return {};
      default:
        return state;
    }
  }
  export const articuloDetailsReducer = (state = { articulo: { reviews: [] } }, action)=> {
    switch (action.type) {
      case ARTICULO_DETAILS_REQUEST:
        return { loading: true };
      case ARTICULO_DETAILS_SUCCESS:
        return { loading: false, articulo: action.payload };
      case ARTICULO_DETAILS_FAIL:
        return { loading: false, error: action.payload }
       
      default:
        return state;
    }
  }