export const FETCH_PRODUCTS = "FETCH_PRODUCTS";
export const FILTER_PRODUCTS_BY_CATEGORY= "FILTER_PRODUCTS_BY_CATEGORY";
export const ORDER_PRODUCTS_BY_PRICE = "ORDER_PRODUCTS_BY_PRICE";
export const FETCH_PRODUCTS2 = "FETCH_PRODUCTS2;"
export const FETCH_PROMOTIONS='FETCH_PROMOTIONS';
export const FETCH_CATEGORIES='FETCH_CATEGORIES';

export const ADD_TO_CART = "ADD_TO_CART";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const CART_SAVE_FACTURACION ="CART_SAVE_FACTURACION";
export const CART_SAVE_PAYMENT = "CART_SAVE_PAYMENT";

export const PRODUCT_DETAILS_REQUEST ="PRODUCT_DETAILS_REQUEST";
export const PRODUCT_DETAILS_SUCCESS = "PRODUCT_DETAILS_SUCCESS";
export const PRODUCT_DETAILS_FAIL ="PRODUCT_DETAILS_FAIL";

export const FETCH_PROJECTS = "FETCH_PROJECTS";
export const FILTER_PROJECTS_BY_PLAN = "FILTER_PROJECTS_BY_PLAN";

export const FETCH_VIDEOS = "FETCH_VIDEOS";

export const USER_REGISTER_DONADOR_FAIL = "USER_REGISTER_DONADOR_FAIL";
export const USER_REGISTER_DONADOR_REQUEST ="USER_REGISTER_DONADOR_REQUEST";
export const USER_REGISTER_DONADOR_SUCCESS = "USER_REGISTER_DONADOR_SUCCESS"


export const USER_REGISTER_CONTACTO_REQUEST = "USER_REGISTER_CONTACTO_REQUEST";
export const USER_REGISTER_CONTACTO_SUCCESS = "USER_REGISTER_CONTACTO_SUCCESS";
export const USER_REGISTER_CONTACTO_FAIL = " USER_REGISTER_CONTACTO_FAIL";



export const USER_SIGNIN_REQUEST = 'USER_SIGNIN_REQUEST';
export const USER_SIGNIN_SUCCESS = 'USER_SIGNIN_SUCCESS';
export const USER_SIGNIN_FAIL = 'USER_SIGNIN_FAIL';

export const USER_UPDATE_REQUEST = 'USER_UPDATE_REQUEST';
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS';
export const USER_UPDATE_FAIL = 'USER_UPDATE_FAIL';

export const USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST';
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS';
export const USER_REGISTER_FAIL = 'USER_REGISTER_FAIL';

export const USER_LOGOUT = 'USER_LOGOUT';

export const PRODUCT_LIST_REQUEST = 'PRODUCT_LIST_REQUEST';
export const PRODUCT_LIST_SUCCESS = 'PRODUCT_LIST_SUCCESS';
export const PRODUCT_LIST_FAIL = 'PRODUCT_LIST_FAIL';

export const FETCH_ARTICULOS = "FETCH_ARTICULOS";

export const ORDER_CREATE_REQUEST = 'ORDER_CREATE_REQUEST';
export const ORDER_CREATE_SUCCESS = 'ORDER_CREATE_SUCCESS';
export const ORDER_CREATE_FAIL = 'ORDER_CREATE_FAIL';
export const ORDER_CREATE_RESET = 'ORDER_CREATE_RESET';

export const ORDER_PAY_REQUEST = 'ORDER_PAY_REQUEST';
export const ORDER_PAY_SUCCESS = 'ORDER_PAY_SUCCESS';
export const ORDER_PAY_FAIL = 'ORDER_PAY_FAIL';
export const ORDER_PAY_RESET = 'ORDER_PAY_RESET';

export const ORDER_DETAILS_REQUEST = 'ORDER_DETAILS_REQUEST';
export const ORDER_DETAILS_SUCCESS = 'ORDER_DETAILS_SUCCESS';
export const ORDER_DETAILS_FAIL = 'ORDER_DETAILS_FAIL';
export const FETCH_ORDERS = "FETCH_ORDERS";
export const CART_EMPY = "CART_EMPY";

export const FILTER_FISCALIZACION_BY_DATE= "FILTER_FISCALIZACION_BY_DATE";
export const FETCH_FISCALIZACION = "FETCH_FISCALIZACION";
export const CART_SAVE_VOLUNTARIADO = "CART_SAVE_VOLUNTARIADO";
export const ARTICULO_REVIEW_SAVE_REQUEST = 'ARTICULO_REVIEW_SAVE_REQUEST';
export const ARTICULO_REVIEW_SAVE_SUCCESS = 'ARTICULO_REVIEW_SAVE_SUCCESS';
export const ARTICULO_REVIEW_SAVE_FAIL = 'ARTICULO_REVIEW_SAVE_FAIL';
export const ARTICULO_REVIEW_SAVE_RESET = 'ARTICULO_REVIEW_SAVE_RESET';
export const ARTICULO_DETAILS_REQUEST ='ARTICULO_DETAILS_REQUEST';
export const ARTICULO_DETAILS_SUCCESS ='ARTICULO_DETAILS_SUCCESS';
export const ARTICULO_DETAILS_FAIL ='ARTICULO_DETAILS_FAIL';
export const ARTICULOS_LIST_REQUEST="ARTICULOS_LIST_REQUEST";
export const ARTICULOS_LIST_FAIL ="ARTICULOS_LIST_FAIL";



