import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-daterangepicker/daterangepicker.css';





  ReactDOM.render(
    <App/>,document.getElementById("root"));

