import {CART_SAVE_VOLUNTARIADO, FETCH_PROJECTS,USER_REGISTER_DONADOR_FAIL, USER_REGISTER_DONADOR_REQUEST, USER_REGISTER_DONADOR_SUCCESS} from "../Types";





export const projectsReducer = (state = {}, action) => {
    switch (action.type) {
  

  
      case FETCH_PROJECTS:
        return { items: action.payload, filteredItems: action.payload};
        case CART_SAVE_VOLUNTARIADO:
        return { ...state, voluntariado: action.payload };
      default:
        return state;
    }
    
  };

  
  export const userRegisterDonadorReducer = (state = {}, action) => {
    switch (action.type) {
      case USER_REGISTER_DONADOR_REQUEST:
        return { loading: true };
      case USER_REGISTER_DONADOR_SUCCESS:
        return { loading: false, userInfo: action.payload };
      case USER_REGISTER_DONADOR_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  }