import {
  FETCH_PROJECTS,
  CART_SAVE_VOLUNTARIADO,
  USER_REGISTER_DONADOR_REQUEST,
  USER_REGISTER_DONADOR_SUCCESS,
  USER_REGISTER_DONADOR_FAIL,
  USER_REGISTER_CONTACTO_REQUEST,
  USER_REGISTER_CONTACTO_SUCCESS,
  USER_REGISTER_CONTACTO_FAIL,
} from "../Types";
import { URL_SERVER } from "../constants/userConstants";
import Axios from "axios";
import swal from "sweetalert";

// export const fetchProjects = () => async (dispatch) => {
//   const res = await fetch(URL_SERVER + "/projects");
//   const data = await res.json();
//   console.log(data.data);
//   dispatch({
//     type: FETCH_PROJECTS,
//     payload: data.data,
//   });
// };
export const fetchProjects = () => async (dispatch) => {
  try {
    const { data } = await Axios.get(URL_SERVER + "/projects");
    dispatch({ type: FETCH_PROJECTS, payload: data.data });
  } catch (error) {
    dispatch({ payload: error.message });
  }
};


const registerDonador = (id_proyecto, nombre, documento, email, telefono, motivo,anonimo) => async (dispatch) => {
  dispatch({ type: USER_REGISTER_DONADOR_REQUEST, payload: { id_proyecto:id_proyecto, nombre:nombre,documento:documento,email:email,telefono:telefono,motivo:motivo, anonimo:anonimo } });

  try {
    const { data } = await Axios.post(URL_SERVER+"/registerDonor", {id_proyecto:id_proyecto, nombre:nombre,documento:documento,email:email,telefono:telefono,motivo:motivo, anonimo:anonimo });
    dispatch({ type: USER_REGISTER_DONADOR_SUCCESS, payload: data.data });
    localStorage.setItem('donador', JSON.stringify(data));
    console.log(data.data)
  } catch (error) {
    dispatch({ type: USER_REGISTER_DONADOR_FAIL, payload: error.message });
  }
}


const registerVoluntariado = (nombre,fecha_nacimiento,email,telefono,ciudad,domicilio) => async (dispatch) => {
  dispatch({ type: USER_REGISTER_DONADOR_REQUEST, payload: {nombre:nombre,fecha_nacimiento:fecha_nacimiento,email:email,telefono:telefono,ciudad:ciudad,domicilio:domicilio} });

  try {
    const { data } = await Axios.post(URL_SERVER+"/registerVoluntary", { nombre:nombre,fecha_nacimiento:fecha_nacimiento,email:email,telefono:telefono,ciudad:ciudad,domicilio:domicilio });

    if(data.status ==="true") {
     
      swal({
        title: "Enviado!",
        text: "Gracias por sumarte al movimiento Ciudadanía Activa",
        dangerMode: true,
        icon: "success",
        button: "ok",
        closeModal: true,
      }).then((willDelete) => {
        if (willDelete) {
          window.location.replace("/");
        }
      });
      dispatch({ type: USER_REGISTER_DONADOR_SUCCESS, payload: data.data });
 
      localStorage.setItem('donador', JSON.stringify(data));
      // dispatch({ type: USER_SIGNIN_SUCCESS, payload: data.data });
      //localStorage.setItem('userInfo', JSON.stringify(data));
    }else{
      swal({
        title: "Gracias por sumarte al movimiento Ciudadanía Activa",
        text: data.message,
        icon: "",
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          window.location.replace("/");
        }
      });
    }
    
  } catch (error) {
    swal({
      title: "¡Ups!",
      text: error.message,
      icon: "",
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
      }
    });
    console.log(error.message)
    dispatch({ type: USER_REGISTER_DONADOR_FAIL, payload: error.message });
  }

}


const contacto = (nombre,email,telefono,mensaje) => async (dispatch) => {
  dispatch({ type: USER_REGISTER_CONTACTO_REQUEST, payload: { nombre:nombre, email:email, telefono:telefono,mensaje:mensaje  } });

  try {
    const { data } = await Axios.post(URL_SERVER+"/contact", { nombre:nombre, email:email, telefono:telefono,mensaje:mensaje });
    dispatch({ type: USER_REGISTER_CONTACTO_SUCCESS, payload: data.data });
 
    localStorage.setItem('userInfo', JSON.stringify(data));
  } catch (error) {
    dispatch({ type: USER_REGISTER_CONTACTO_FAIL, payload: error.message });
  }
}





// export const saveVoluntariado = (data) => (dispatch) => {
//   dispatch({ type: CART_SAVE_VOLUNTARIADO, payload: data });
//   localStorage.setItem('Voluntariadoform', JSON.stringify(data));
// }

export {registerDonador,registerVoluntariado,contacto};