import React from 'react';
import Footer from '../Layout/Footer'
import NavigationNuevo from './NavigationNuevo';


const LayoutNuevo = ({children}) => (
    <div className="App">
        <NavigationNuevo />
        <div className="main2">
        {children}
        </div>
        <Footer />

            {/* <img src="/imagenes/sello.png" className="sello" alt="El sello indica que este proyecto es propiedad de Fundación Proinso" />
            <img src="/imagenes/icc-109.png" className="sello2" alt="whatsapp" /> */}

     
    </div>

);
export default LayoutNuevo;
