import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { productsReducer,productReviewSaveReducer,} from "./reducers/productReducer";
import {videosReducer} from "./reducers/videosReducer";
import {userSigninReducer, userRegisterReducer,userUpdateProfileReducer, userDetailsReducer,userRecuperarReducer,userSigninFacebookReducer} from './reducers/userReducers';
import { projectsReducer,userRegisterDonadorReducer } from './reducers/projectReducer';
import { cartReducer } from './reducers/cartReducer';
import { articulosReducer,articuloDetailsReducer,articuloReviewSaveReducer } from './reducers/articuloReducer';
import { productListReducer, productDetailsReducer} from './reducers/productReducer';
import {  orderCreateReducer,
  orderDetailsReducer,
} from './reducers/orderReducer';
  import Cookie from 'js-cookie';


  
let userInfo 
try {
  
   userInfo = JSON.parse(localStorage.getItem('userInfo'));
} catch (error) {
   userInfo =  null;
}

  const initialState = {
     userSignin: { userInfo },
    // userSignin: {
    //   userInfo: localStorage.getItem('userInfo')
    //     ? JSON.parse(localStorage.getItem('userInfo'))
    //     : null,
    // },
    cart: {
      cartItems: localStorage.getItem('cartItems')
        ? JSON.parse(localStorage.getItem('cartItems'))
        : [],
        // facturacion: localStorage.getItem('facturacion')
        // ? JSON.parse(localStorage.getItem('facturacion'))
        // : {},

    },
  };
const reducer = combineReducers({

  userSignin: userSigninReducer,
  userRegister: userRegisterReducer,
  products: productsReducer,
  videos: videosReducer,
  projects:projectsReducer,
  cart:cartReducer,
  articulos:articulosReducer,
  productList: productListReducer,
  orderCreate: orderCreateReducer,
  orderDetails: orderDetailsReducer,
  productReviewSave: productReviewSaveReducer,
  productDetails: productDetailsReducer,
  articuloDetails:articuloDetailsReducer,
  articuloReviewSave:articuloReviewSaveReducer,
  userUpdateProfile: userUpdateProfileReducer,
  userDetails: userDetailsReducer,
  userRecuperar:userRecuperarReducer,
  userSigninFacebook:userSigninFacebookReducer,
  userRegisterDonador:userRegisterDonadorReducer,
  

 

});
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  reducer,
  initialState,
  composeEnhancer(applyMiddleware(thunk))
);
export default store;