import React, { Component } from "react";
import "../Shopping.css";

class Footer extends Component {
  render() {
    return (
      <div className="site-footer">
        <div className=" centrar"></div>
        <br/><br/>
        <p className="color-white centrar">Ciudadanía Activa 2021</p>
          {/* <div className="logo-nav-2-redes">
            <a href="https://www.facebook.com/ciudadaniactivapy">
              <img
                src="../../mca/logo-fb.png"
                className="redes-sociales-22"
                alt="facebook"
              />
            </a>

            <a href="https://www.instagram.com/ciudadaniactivapy/">
              <img
                src="../../mca/logo-ig.png"
                className="redes-sociales-22"
                alt="instagram"
              />
            </a>

            <a href="https://twitter.com/movciudadaniapy/">
              <img
                src="../../mca/logo-tw.png"
                className="redes-sociales-22"
                alt=""
              />
            </a>
          </div> */}
        </div>
      
    );
  }
}

export default Footer;
