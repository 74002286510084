import React, { lazy, Suspense } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";

import store from "./store";
import Spinner from "./components/Spinner";
import Error404 from "./screens/Error404";
import HomePrincipal2 from "./screens/HomePrincipal2";
import HomePrincipal from './screens/HomePrincipal';



const BonosCompra = lazy(() => import("./screens/BonosCompra"));
const SinginScreen = lazy(() => import("./pages/SinginScreen"));
const RegisterScreen = lazy(() => import("./pages/RegisterScreen"));
// const HomePricipal2 = lazy(() => import("./screens/HomePrincipal2"));
const HomeScreen = lazy(() => import("./screens/HomeScreen"));
const Donaciones = lazy(() => import("./screens/Donaciones"));
const Padrinazgo = lazy(() => import("./screens/Padrinazgo"));
const Voluntariado = lazy(() => import("./screens/Voluntariado"));
const HomeApoyanos = lazy(() => import("./screens/HomeApoyanos"));
const Voluntariadoform = lazy(() => import("./pages/Voluntariadoform"));
const Fiscalizacionhome = lazy(() =>
  import("./Fiscalizacion/Fiscalizacionhome")
);
const HomeCarrito = lazy(() => import("./screens/HomeCarrito"));
const Contacto = lazy(() => import("./pages/Contacto"));
const Repetirc = lazy(() => import("./pages/Repetirc"));
const Contraseña = lazy(() => import("./pages/Contraseña"));
const Alertacont = lazy(() => import("./pages/Alertacont"));
const Payment = lazy(() => import("./screens/Payment"));
const HomeMejora = lazy(() => import("./screens/Payment"));
const IngresosEgresos = lazy(() => import("./Fiscalizacion/IngresosEgresos"));
const EntregaBeneficiarios = lazy(() =>
  import("./Fiscalizacion/EntregaBeneficiarios")
);
const ExistenciaD = lazy(() => import("./Fiscalizacion/ExistenciaD"));
const DonacionesyP = lazy(() => import("./Fiscalizacion/DonacionesyP"));
const OrderScreen = lazy(() => import("./screens/OrderScreen"));
const HomeOrder = lazy(() => import("./screens/HomeOrder"));
const DetallesdeA = lazy(() => import("./Fiscalizacion/DetallesdeA"));
const Donacionesform = lazy(() => import("./pages/Donacionesform"));
const Padrinazgoform = lazy(() => import("./pages/Padrinazgoform"));
const RatingScreen = lazy(() => import("./screens/RatingScreen"));
const RatingScreenArt = lazy(() => import("./screens/RatingScreenArt"));
const PerfilScreen = lazy(() => import("./screens/PerfilScreen"));
const QuienesSomos = lazy(() => import("./pages/QuienesSomos"));
const ReglasJuego = lazy(() => import("./contenido/ReglasJuego"));
const Alimentacion = lazy(() => import("./contenido/Alimentacion"));
const Faqs = lazy(() => import("./components/Faqs"));
const PrivateRoute = lazy(() => import("./Layout/PrivateRoute"));
const Terminos = lazy(() => import("./screens/Terminos"));
const NotasHome = lazy(() => import("./screens/NotasHome"));
// import SinginScreen from "./pages/SinginScreen";
// import RegisterScreen from "./pages/RegisterScreen";

// import HomeScreen from './screens/HomeScreen';
// import Donaciones from './screens/Donaciones'
// import Padrinazgo from "./screens/Padrinazgo";
// import Voluntariado from "./screens/Voluntariado";
// import HomeApoyanos from './screens/HomeApoyanos';
// import Voluntariadoform from "./pages/Voluntariadoform";
// import Fiscalizacionhome from './Fiscalizacion/Fiscalizacionhome';
// import HomeCarrito from './screens/HomeCarrito';
// import Contacto from './pages/Contacto';
// import Repetirc from './pages/Repetirc'
// import Contraseña from './pages/Contraseña';
// import Alertacont from './pages/Alertacont';
// import HomeCompra from "./screens/HomeCompra";
// import Payment from "./screens/Payment";
// import HomeMejora from "./screens/HomeMejora";
// import IngresosEgresos from "./Fiscalizacion/IngresosEgresos";
// import EntregaBeneficiarios from "./Fiscalizacion/EntregaBeneficiarios";
// import ExistenciaD from "./Fiscalizacion/ExistenciaD";
// import DonacionesyP from "./Fiscalizacion/DonacionesyP";

// import OrderScreen from './screens/OrderScreen';
// import HomeOrder from "./screens/HomeOrder";
// import DetallesdeA from "./Fiscalizacion/DetallesdeA";
// import Donacionesform from "./pages/Donacionesform";
// import Padrinazgoform from "./pages/Padrinazgoform";
// import RatingScreen from "./screens/RatingScreen";
// import RatingScreenArt from "./screens/RatingScreenArt";
// import PerfilScreen from "./screens/PerfilScreen";
// import QuienesSomos from "./pages/QuienesSomos";
// import ReglasJuego from "./contenido/ReglasJuego";
// import Alimentacion from "./contenido/Alimentacion";
// import Faqs from "./components/Faqs";
// import PrivateRoute from "./Layout/PrivateRoute"
// import Terminos from "./screens/Terminos";
// import NotasHome from "./screens/NotasHome";

class App extends React.Component {
  render() {
    let loading;

    loading = (
      <Switch>
       
        <Route path="/shopping" component={HomeScreen}></Route>
        <Route path="/index-1" exact={true} component={HomePrincipal}></Route>
        <Route path="/" exact={true} component={HomePrincipal2}></Route>
        <Route path="/register" component={RegisterScreen}></Route>
        <Route path="/apoyanos" component={HomeApoyanos}></Route>
        <Route path="/donaciones" component={Donaciones}></Route>
        <Route path="/donaciones-form/:id" component={Donacionesform}></Route>
        <Route path="/proyectos-en-tu-barrio" component={Padrinazgo}></Route>
        <Route
          path="/padrinazgo-form/:id"
          exact={true}
          component={Padrinazgoform}
        ></Route>
        <Route path="/voluntariado" component={Voluntariado}></Route>
      
        <Route
          path="/voluntariado-form"
          component={HomePrincipal2}
        ></Route>
        <Route path="/contacto" component={Contacto}></Route>
        <Route path="/carrito" component={HomeCarrito}></Route>
        <Route path="/Conocenos" component={QuienesSomos}></Route>
        <Route path="/products/:id" component={RatingScreen} />
        <Route path="/articles/:id" component={RatingScreenArt}></Route>
        <PrivateRoute path="/perfil" component={PerfilScreen}></PrivateRoute>
        <Route path="/reglas-del-juego" component={ReglasJuego}></Route>
        <Route path="/alimentacion" component={Alimentacion}></Route>
        <Route path="/notas" component={NotasHome}></Route>
        <Route path="/fiscalizacion" component={Fiscalizacionhome}></Route>
        <Route path="/mejora-tu-rendimiento" component={HomeMejora}></Route>
        <Route path="/signin" component={SinginScreen}></Route>
        <Route path="/codigo" component={Contraseña}></Route>
        <Route path="/preguntas-frecuentes" component={Faqs}></Route>
        <Route path="/terminos-y-condiciones" component={Terminos}></Route>

                <Route path="/payment" component={Payment}></Route>
       
       <Route path="/ingresos-egresos" component={IngresosEgresos}></Route>
       <Route
         path="/entrega-beneficiarios"
         component={EntregaBeneficiarios}
       ></Route>
       <Route path="/existencia-donaciones" component={ExistenciaD}></Route>
       <Route path="/Donaciones-padrinazgo" component={DonacionesyP}></Route>
       <Route path="/detalles-aporte" component={DetallesdeA}></Route>
       <Route path="/mis-compras" component={OrderScreen}></Route>
       <Route path="/placeorder" component={HomeOrder}></Route>
       <Route path="/pagos" component={BonosCompra}></Route>
       <Route exact path="*" component={Error404}></Route>
       
        
        {/* <Route path="/Recuperar-contraseña" component={Repetirc}></Route> */}
    

     
        {/* <Route path="/facturacion" component={HomeCompra} ></Route> */}
       

    
      </Switch>
    );

    return (
      <Provider store={store}>
        <BrowserRouter>
          <Suspense fallback={<Spinner />}>{loading}</Suspense>
        </BrowserRouter>
      </Provider>
    );
  }
}

export default App;
