import {
  USER_SIGNIN_REQUEST,
  USER_SIGNIN_SUCCESS,
  USER_SIGNIN_FAIL,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_FAIL,
  USER_LOGOUT,USER_UPDATE_PROFILE_REQUEST,
  USER_UPDATE_PROFILE_SUCCESS,
  USER_UPDATE_PROFILE_FAIL,
  USER_UPDATE_PROFILE_RESET,
  USER_DETAILS_REQUEST,
  USER_DETAILS_SUCCESS,
  USER_DETAILS_FAIL,
  USER_RECUPERAR_REQUEST,
  USER_RECUPERAR_FAIL,
  USER_RECUPERAR_SUCCESS,
  USER_FACEBOOK_REQUEST,
  USER_FACEBOOK_SUCCESS,
  USER_FACEBOOK_FAIL,
  USER_FACEBOOK_LOGOUT,
} from "../constants/userConstants";


function userSigninReducer(state = {}, action) {
  
  switch (action.type) {
    case USER_SIGNIN_REQUEST:
      return { loading: true };
    case USER_SIGNIN_SUCCESS:
      return { loading: false, userInfo: action.payload };
    case USER_SIGNIN_FAIL:
      return { loading: false, error: action.payload };
    case USER_LOGOUT:
      return {};
    default:
      return state;
  }
}
function userSigninFacebookReducer(state = {}, action) {
  switch (action.type) {
    case USER_FACEBOOK_REQUEST:
      return { loading: true };
    case USER_FACEBOOK_SUCCESS:
      return { loading: false, userInfo: action.payload };
    case USER_FACEBOOK_FAIL:
      return { loading: false, error: action.payload };
    case USER_FACEBOOK_LOGOUT:
      return {};
    default:
      return state;
  }
}

function userRecuperarReducer(state = {}, action) {
  switch (action.type) {
    case USER_RECUPERAR_REQUEST:
      return { loading: true };
    case USER_RECUPERAR_SUCCESS:
      return { loading: false, userInfo: action.payload };
    case USER_RECUPERAR_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
}

export const userDetailsReducer = (state = { loading: true }, action) => {
  switch (action.type) {
    case USER_DETAILS_REQUEST:
      return { loading: true };
    case USER_DETAILS_SUCCESS:
      return { loading: false, user: action.payload };
    case USER_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
export const userUpdateProfileReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_UPDATE_PROFILE_REQUEST:
      return { loading: true };
    case USER_UPDATE_PROFILE_SUCCESS:
      return { loading: false, success: true };
    case USER_UPDATE_PROFILE_FAIL:
      return { loading: false, error: action.payload };
    case USER_UPDATE_PROFILE_RESET:
      return {};
    default:
      return state;
  }
};

function userRegisterReducer(state = {}, action) {
  switch (action.type) {
    case USER_REGISTER_REQUEST:
      return { loading: true };
    case USER_REGISTER_SUCCESS:
      return { loading: false, userInfo: action.payload };
    case USER_REGISTER_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}


export { userSigninReducer, userRegisterReducer,userRecuperarReducer, userSigninFacebookReducer };
