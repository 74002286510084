import React from "react";
import { Link } from "react-router-dom";
import Layout from "../Layout/Layout";



const Error404 = () => (
    <Layout>
  <div className="container-notf">
    <img
    src="../../mca/errornotfound.png"
     
      className="img-notfound"
      alt="productos"
    />
    <Link className="link-notfound link-naranja" to="/">
      Volver al Inicio ¡Click aquí!
    </Link>
  </div>
  </Layout>
);
export default Error404;