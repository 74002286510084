import React, { Component } from "react";
import "../Shopping.css";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import Layout from "../Layout/LayoutNuevo";
import Zoom from "react-reveal/Zoom";
import { connect } from "react-redux";
import { fetchProducts } from "../actions/ProductActions";
import { addToCart } from "../actions/CartActions";
import Modal from "react-modal";
import Promociones from "../components/Promociones";
import MiEspacio from "../Layout/MiEspacio";
import { Helmet } from "react-helmet";
import LayoutNuevo from "../Layout/LayoutNuevo";
import Voluntariadoform from "../pages/Voluntariadoform"

class HomePrincipal2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      product: null,
    };
  }

  openModal = (product) => {
    this.setState({ product });
  };
  closeModal = () => {
    this.setState({ product: null });
  };
  render() {
    const { product } = this.state;
    /**Galeria img Nuestro juego */
    return (
      <div>
        <LayoutNuevo>
          <Helmet>
            <title>Ciudadanía activa</title>
            <meta
              name="description"
              content="Nuestra propuesta deportiva asume un enfoque de aprendizaje integral y permite el crecimiento personal de todos los individuos relacionados con el fútbol"
            />
            <meta charset="utf-8" />
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1"
            />
            <link rel="icon" href="%PUBLIC_URL%mca/logo-celeste.png" />
            <html lang="es" />
            <meta name="theme-color" content="#000000" />
            <meta name="robots" content="index, follow"></meta>
          </Helmet>
          <div className="rect-200">

   <header className="centrar">
   <img
                      src="../../mca/titulo.png"
                      className="img-fondo-nuevo222"
                      alt="carrito de compras"
                    />
                    <br/>

   </header>
  <main className="centrar centrar-h">

   
   <img
                     src="../../mca/flyer-celu.jpg"
                     className="img-fondo-nuevo-celu"
                     alt="debate"
                   /> 
                      <img
                     src="../../mca/flyer.jpg"
                     className="img-fondo-nuevo"
                     alt="debate"
                   /> 
         
 
   </main>
   <div className="centrar">
     <br/>
     <a href="https://fb.watch/5lvh0a5xpX/" target="_blank">
  
   </a>
   <br/>
   </div>
 <div>

   <div className="logo-nav-2-redes">
   <img
                src="../../mca/seguinos.png"
                className="redes-sociales-22"
                alt="facebook"
              />
            <a href="https://www.facebook.com/ciudadaniactivapy" target="_blank">
              <img
                src="../../mca/fb@2x.png"
                className="redes-sociales-22"
                alt="facebook"
              />
            </a>

            <a href="https://www.instagram.com/ciudadaniactivapy/">
              <img
                src="../../mca/ig@2x.png"
                className="redes-sociales-22"
                alt="instagram"
              />
            </a>

            <a href="https://twitter.com/movciudadaniapy/">
              <img
                src="../../mca/tw@2x.png"
                className="redes-sociales-22"
                alt=""
              />
            </a>
          </div>

          </div>

          <main className="container-juego centrar">
           
            <br/><br/><br/><br/>
<Voluntariadoform/>
          </main>




         
          

         



                   
                  
               
              
          </div>
         
        </LayoutNuevo>
      </div>
    );
  }
}

export default connect((state) => ({ products: state.products.Items }), {
  fetchProducts,
  addToCart,
})(HomePrincipal2);
