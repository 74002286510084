import React, { Component } from "react";
import "../Shopping.css";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import Layout from "../Layout/Layout";
import Zoom from "react-reveal/Zoom";
import { connect } from "react-redux";
import { fetchProducts } from "../actions/ProductActions";
import { addToCart } from "../actions/CartActions";
import Modal from "react-modal";
import Promociones from "../components/Promociones";
import MiEspacio from "../Layout/MiEspacio";
import { Helmet } from "react-helmet";

class HomePrincipal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      product: null,
    };
  }

  openModal = (product) => {
    this.setState({ product });
  };
  closeModal = () => {
    this.setState({ product: null });
  };
  render() {
    const { product } = this.state;
    /**Galeria img Nuestro juego */
    return (
      <div className="fondo-cancha">
        <Layout>
          <Helmet>
            <title>Ciudadanía activa</title>
            <meta
              name="description"
              content="Nuestra propuesta deportiva asume un enfoque de aprendizaje integral y permite el crecimiento personal de todos los individuos relacionados con el fútbol"
            />
            <meta charset="utf-8" />
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1"
            />
            <link rel="icon" href="%PUBLIC_URL%mca/logo-celeste.png" />
            <html lang="es" />
            <meta name="theme-color" content="#000000" />
            <meta name="robots" content="index, follow"></meta>
          </Helmet>
          {/* <MiEspacio /> */}
          <header>
            {/* <img
              src="/imagenes/banner.png"
              className="banner"
              alt="publicidad"
            /> */}

         
            <br />

          </header>
          <div class="row row2000">
    <div class="col">
    <div className="container-menu-redondos11 ">

              <Link to="/donaciones" className="iconos-r-primeros">
                <img
                  src="/mca/dona22.png"
                  className="iconos-r-primero"
                  alt="dona"
                />
              </Link>
              <Link to="/voluntariado" >
                <img
                  src="/mca/voluntariado22.png"
                  className="iconos-redondos"
                  alt="voluntarios"
                />
              </Link>
              <Link to="/proyectos-en-tu-barrio" >
                <img
                  src="/mca/proyectos22.png"
                  className="iconos-redondos"
                  alt="proyectos"
                />
              </Link>
              <Link to="/fiscalizacion">
                <img
                  src="/mca/fiscaliza22.png"
                  className="iconos-redondos"
                  alt="fiscaliza."
                />
              </Link>
            </div>
    </div>
    <div class="col-5 col5">

<div className="centrar">
          <img
            src="/imagenes/nuestros-juego1.jpg"
            className="img-testimonial"
            alt="Charla en la Escuela Nacional de Educación Física
              (Gobernación de Cordillera) sobre Nutrigenética y nutrigenómica en el fútbol"
          />
</div>


          <div className="container-juego">
            <p className="centrar nuestro-juego">
              Creemos en el fútbol como una herramienta de desarrollo
              individual, social y económico. A través de nuestra secuencia con
              videos de contenido pedagógico para el dominio del balón; podrás

            </p>
          </div>

          <br />
          <br />
          <br />
          <main>
            <h2 className="centrar">PROMOCIONES</h2>
            <Promociones />
          </main>

          <br />
          <br />
          <br />
          <br />
<div className="centrar">
          <Link to="/shopping" >
            <button className="button-red-promociones"> Ver todo </button>
          </Link>
          </div>
          <br />
          <br />
          <br />

    </div>
    <div class="col">

            <div className="container-menu-redondos">
              <Link to="/conocenos" className="iconos-r-primeros">
                <img
                  src="/mca/qs22.png"
                  className="iconos-r-primero"
                  alt="quienes somos"
                />
              </Link>
              <Link to="/shopping" className="iconos-r-primeros">
                <img
                  src="/mca/shopping22.png"
                  className="iconos-redondos"
                  alt="shopping"
                />
              </Link>
              <Link to="/notas" className="iconos-r-primeros">
                <img
                  src="/mca/notas22.png"
                  className="iconos-redondos"
                  alt="notas"
                />
              </Link>
              <Link to="/contacto" className="iconos-r-primeros">
                <img
                  src="/mca/contactanos22.png"
                  className="iconos-redondos"
                  alt="contacto"
                />
              </Link>
            </div>
    </div>
  </div>

         
          

         


          {product && (
            <Modal
              className="my-modal"
              isOpen={true}
              onRequestClose={this.closeModal}
            >
              <Zoom>
                <button className="close-modal" onClick={this.closeModal}>
                  X
                </button>
                <hr />
                <div className="product-details">
                  <img src={product.image} alt={product.name}></img>
                  <div className="product-details-description">
                    <h2>
                      <strong>
                        {product.name}
                        <br />
                        Descripción:{" "}
                      </strong>
                    </h2>

                    <p>${product.description}</p>

                    <div className="product-price">
                      <hr />
                      <h1 className="centrar">RESUMEN</h1>

                      <p className=" centrar price">${product.price}</p>

                      <hr />
                      <div>
                        <h1 className="centrado">PASOS</h1>
                        <ol className="ol">
                          <li>
                            <p>
                              Presiona el botón comprar o seguí agregardo al
                              carrito;
                            </p>
                          </li>
                          <li>
                            <p>
                              Crear tu cuenta, o inicia sesión directamente si
                              ya tienes una;
                            </p>
                          </li>
                          <li>
                            <p>Elije el método de pago de tu preferencia</p>
                          </li>
                          <li>
                            <p>¡Disfruta!</p>
                          </li>
                        </ol>
                        <button className="button-red-forms">Comprar</button>
                        <br />
                        <button
                          className="button-transparente-forms"
                          onClick={() => {
                            this.props.addToCart(product);
                            this.closeModal();
                          }}
                        >
                          Agregar al carrito
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Zoom>
            </Modal>
          )}
        </Layout>
      </div>
    );
  }
}

export default connect((state) => ({ products: state.products.Items }), {
  fetchProducts,
  addToCart,
})(HomePrincipal);
