import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { logout, update } from "../actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import "../Shopping.css";

function Alertacont(props) {
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const dispatch = useDispatch();

  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;
  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <div>
      {userInfo ? (
        <Link className="lista-sidebar-700-iz" to="/mis-compras">
          <p>Mis Compras</p>
        </Link>
      ) : (
        <div></div>
      )}
    </div>
  );
}

export default Alertacont;
