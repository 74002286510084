import React from "react";
// import { logout } from "../action/UserActions";
import { useDispatch, useSelector } from "react-redux";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import MiEspacio from "./MiEspacio";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  paper: {
    marginRight: theme.spacing(2),
  },
}));

export default function MenuUser() {
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const dispatch = useDispatch();

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  //   const handleLogout = () => {
  //     dispatch(logout());
  //   };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);
  //console.log(userInfo);
  let user;
  if (userInfo) {
    user = userInfo.nombre
      ? userInfo.nombre.substring(0, 18) + "..."
      : userInfo.nombre;
  }
  return (
    <div className={classes.root}>
      <div>
        <Button
          ref={anchorRef}
          aria-controls={open ? "menu-list-grow" : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        >
          <img
            src="../../mca/ic.png"
            className="menu-user22"
            alt="contacto"
          />
        </Button>
        <Popper
          className="user-m"
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="menu-user"
                    onKeyDown={handleListKeyDown}
                  >
                    <Link to="/mi-cuenta">
                      {/* <MenuItem className="MenuItem" onClick={handleClose}><h2 className="color-black">Mis Puntos: {userInfo.total_puntos}</h2></MenuItem> */}
                    </Link>
                    
                      <MenuItem className="MenuItem" onClick={handleClose}>
                        <p className="color-black">
                          {userInfo ? (
                                <p className="saludo color-orange"> ¡Hola,{user}! </p>
                          ) : (
                            <></>
                          )}
                        </p>
                      </MenuItem>
                    
                    <Link to="/perfil">
                      <MenuItem className="MenuItem" onClick={handleClose}>
                        <p className="color-black">Mis datos</p>
                      </MenuItem>
                    </Link>
                    <Link to="/mis-compras">
                      <MenuItem className="MenuItem" onClick={handleClose}>
                        <p className="color-black">Mis compras</p>
                      </MenuItem>
                    </Link>
                    <Link to="/">
                      {/* <MenuItem className="MenuItem" onClick={handleLogout}><button className="button-red">Cerrar sesión</button></MenuItem> */}
                    </Link>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  );
}
